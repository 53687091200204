import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import { StaticImage } from "gatsby-plugin-image";
import { useLS } from "../hooks";

const formSteps = [
  {
    heading: "No Risk. All the Rewards: Start Your Free Trial!",
    subText:
      "Real estate professionals everywhere are transforming their businesses with Realsynch. But we don't expect you to take our word for it—experience Realsynch's specialized BI platform risk-free and discover how easily you can:",
    items: [
      "Identify your highest-converting lead sources",
      "Compare agent performance with real-time metrics",
      "Track marketing ROI with automated reporting",
    ],
    postText: "Your competitive edge begins here.",
  },
  {
    heading: "Insights Tailored to Your Business",
    subText:
      "We customize the Realsynch experience around each customer's unique profile. By telling us about your team size and transaction volume, we'll create a dashboard that best suits your needs.",
    items: [],
    postText: "",
  },
  {
    heading: "The Final Step: Personalized Onboarding",
    subText:
      "You're almost there! Schedule a 30-minute call with a Realsynch expert who will:",
    items: [
      "Set up your customized dashboard based",
      "Show you how to identify your highest-impact opportunities",
      "Answer any questions specific to your business challenges",
    ],
    postText:
      "This final step ensures you get maximum value out of your free trial.",
  },
];

const transactionAmounts = [
  "Under 25 transactions",
  "25-100 transactions",
  "101-250 transactions",
  "251-500 transactions",
  "500+ transactions",
];

const teamSizes = [
  "Solo agent",
  "2-5 agents",
  "6-20 agents",
  "21-50 agents",
  "50+ agents",
];

function encode(data) {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }
  return formData;
}

const Page = ({ data }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [disableFirstStep, setDisableFirstStep] = useState(true);
  const HeadingTag = "h2";
  const inputClasses =
    "p-4 rounded-[2px] border-[1px] border-[#808080] bg-white w-full leading-[24px]";
  const [state, setState] = useState({
    name: "",
    phone: "",
    email: "",
    companyName: "",
    teamSize: "Solo agent",
    transactions: "Under 25 transactions",
  });
  const [step1, setStep1] = useLS("step1", "");
  useEffect(() => {
    if (state.name && state.email && state.phone) {
      setDisableFirstStep(false);
    }
  }, [state]);

  const handleChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    const form = e.target;
    console.log(state);
    setStep1(state);
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        name: state.name,
        phone: state.phone,
        email: state.email,
      }),
    })
      .then(
        () =>
          (document.getElementById("contact-form-ajax-response").innerHTML =
            "Thank you for for submission! We will get in touch with you shortly."),
        form.remove(),
        (window.dataLayer = window.dataLayer || []),
        window.dataLayer.push({
          event: "contactFormSubmission",
        })
      )
      .catch((error) => alert(error));
    setActiveStep(1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    console.log(state);
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        name: state.name,
        phone: state.phone,
        email: state.email,
        companyName: state.companyName,
        teamSize: state.teamSize,
        transactions: state.transactions,
      }),
    })
      .then(
        () =>
          (document.getElementById("contact-form-ajax-response").innerHTML =
            "Thank you for for submission! We will get in touch with you shortly."),
        form.remove(),
        (window.dataLayer = window.dataLayer || []),
        window.dataLayer.push({
          event: "contactFormSubmission",
        })
      )
      .catch((error) => alert(error));
    setActiveStep(2);
  };

  return (
    <Layout backgroundColor="#f1eefb" customTheme="light-pink">
      <SearchEngineOptimization
        title="Start Your Free Trial with RealSynch"
        description="Experience the power of automation! Try RealSynch for free and streamline your real estate business operations today."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className={"mt-[64px] lg:mt-[64px]"}>
        <div className="container max-w-[1040px]">
          <div className="mb-16 flex grid-cols-2 flex-col gap-x-20 lg:grid">
            <div className="flex w-full flex-col items-start justify-start">
              <div className="self-start font-body text-sm font-extrabold uppercase text-primary-400">
                Step {activeStep + 1} of 3
              </div>
              <HeadingTag className="self-start">
                {formSteps[activeStep].heading}
              </HeadingTag>
              <p className="font-heading text-lg font-semibold leading-[150%] text-typography-body">
                {formSteps[activeStep].subText}
              </p>
              <ul className="self-start">
                {formSteps[activeStep].items.map((item, index) => (
                  <li
                    className="flex gap-x-[11px] font-heading text-base leading-[24px] text-typography-body"
                    key={index}
                  >
                    {" "}
                    <StaticImage
                      src="../../images/1.0 Homepage/bullet-check.svg"
                      loading="lazy"
                      width={25}
                      height={25}
                    />
                    {item}
                  </li>
                ))}
              </ul>
              <p className="mt-4 font-heading text-lg font-semibold leading-[150%] text-typography-body">
                {formSteps[activeStep].postText}
              </p>
            </div>
            <div>
              <form
                name="Free Trial Partial Submission"
                method="post"
                action=""
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleNextStep}
                className={`rounded-[16px] border-[1px] border-[#CCC] bg-white p-10 drop-shadow-md ${
                  activeStep == 0 ? "block" : "hidden"
                }`}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="Contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={handleChange} />
                  </label>
                </div>
                {/* {activeStep == 0 && ( */}
                <div className={`w-full `}>
                  <div className="mb-5">
                    <label
                      className="mb-1 block font-body text-sm font-semibold text-[#1a1a1a]"
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      onChange={handleChange}
                      placeholder="First Last"
                      required={true}
                      className={inputClasses}
                    />
                  </div>
                  <div className="mb-5">
                    <label
                      className="mb-1 block font-body text-sm font-semibold text-[#1a1a1a]"
                      htmlFor="email"
                    >
                      Email{" "}
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="email@email.com"
                      onChange={handleChange}
                      required={true}
                      className={inputClasses}
                    />
                  </div>
                  <div className="mb-5">
                    <label
                      className="mb-1 block font-body text-sm font-semibold text-[#1a1a1a]"
                      htmlFor="phone"
                    >
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      placeholder="(123) 456-7890"
                      onChange={handleChange}
                      required={true}
                      className={inputClasses}
                    />
                  </div>

                  <div className="grid w-full grid-cols-1 md:grid-cols-2 md:gap-x-6"></div>
                  <ButtonSolid
                    text="Next"
                    className="disabled:opacity-50"
                    altStyle={2}
                    onClick="submit"
                    disabled={disableFirstStep}
                  />
                </div>
              </form>
              <form
                name="Free Trial Complete Submission"
                method="post"
                action=""
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                className={`rounded-[16px] border-[1px] border-[#CCC] bg-white p-10 drop-shadow-md ${
                  activeStep == 1 ? "block" : "hidden"
                }`}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="Contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={handleChange} />
                  </label>
                </div>
                {/* {activeStep == 0 && ( */}
                <div className={`hidden w-full`}>
                  <div className="mb-5">
                    <label
                      className="mb-1 block font-body text-sm font-semibold text-[#1a1a1a]"
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <input
                      type="hidden"
                      name="name"
                      onChange={handleChange}
                      placeholder="First Last"
                      required={true}
                      className={inputClasses}
                    />
                  </div>
                  <div className="mb-5">
                    <label
                      className="mb-1 block font-body text-sm font-semibold text-[#1a1a1a]"
                      htmlFor="email"
                    >
                      Email{" "}
                    </label>
                    <input
                      type="hidden"
                      name="email"
                      placeholder="email@email.com"
                      onChange={handleChange}
                      required={true}
                      className={inputClasses}
                    />
                  </div>
                  <div className="mb-5">
                    <label
                      className="mb-1 block font-body text-sm font-semibold text-[#1a1a1a]"
                      htmlFor="phone"
                    >
                      Phone Number
                    </label>
                    <input
                      type="hidden"
                      name="phone"
                      placeholder="(123) 456-7890"
                      onChange={handleChange}
                      required={true}
                      className={inputClasses}
                    />
                  </div>
                </div>
                {/* )} */}
                {/* {activeStep == 1 && ( */}
                <div
                  className={`w-full ${activeStep == 1 ? "block" : "hidden"}`}
                >
                  <div className="mb-5">
                    <label
                      className="mb-1 block font-body text-sm font-semibold text-[#1a1a1a]"
                      htmlFor="companyName"
                    >
                      Company Name
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      onChange={handleChange}
                      required={true}
                      className={inputClasses}
                    />
                  </div>
                  <div className="mb-5">
                    <label
                      className="mb-1 block font-body text-sm font-semibold text-[#1a1a1a]"
                      htmlFor="teamSize"
                    >
                      Team Size
                    </label>
                    <select
                      id="teamSize"
                      type="text"
                      value={state?.teamSize}
                      name="teamSize"
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          teamSize: e.target.value,
                        }))
                      }
                      className={inputClasses}
                    >
                      {teamSizes.map((option, index) => (
                        <option
                          value={option}
                          className="text-black"
                          key={index}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-5">
                    <label
                      className="mb-1 block font-body text-sm font-semibold text-[#1a1a1a]"
                      htmlFor="transactions"
                    >
                      Transactions per year
                    </label>
                    <select
                      id="transactions"
                      type="text"
                      value={state?.transactions}
                      name="transactions"
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          transactions: e.target.value,
                        }))
                      }
                      className={inputClasses}
                    >
                      {transactionAmounts.map((option, index) => (
                        <option
                          value={option}
                          className="text-black"
                          key={index}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="grid w-full grid-cols-1 md:grid-cols-2 md:gap-x-6"></div>
                  <ButtonSolid
                    type="submit"
                    text="Start Free Trial"
                    altStyle={2}
                  />
                </div>
                {/* )} */}
              </form>
              {activeStep == 2 && (
                <div className="h-screen rounded-[16px] border-[1px] border-[#CCC] bg-white p-10 drop-shadow-md">
                  {" "}
                  <iframe
                    title="Calendly iframe"
                    frameborder="0"
                    src="https://calendly.com/scottsillari/realsynchdemo"
                    width="100%"
                    height="100%"
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    business: file(relativePath: { eq: "home/business.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 528)
      }
    }
  }
`;

export default Page;
